.editor-container {
  background-color: #ffffff;
  overflow: auto;
  margin: 0 5px 5px 5px;
  padding: 0 5px 5px 5px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: black;
}

.preview-container {
  background-color: #ffffff;
  overflow: auto;
  border: 1px black solid;
  margin: 5px;
  padding: 0 5px 5px 5px;
}
.bp3-input-group.bp3-fill.sidebar-search-input{
    margin: 7% 0;
    padding: 5%;
    max-width: 100%;
}
.bp3-input-group.bp3-fill.sidebar-search-input span {
    padding: 5%;
}
/* .side-bar {
    position: fixed;
    width: 240px;
    top: 50px;
    bottom: 0;
    left: 0;
    background: rgba(0, 153, 255, 0.06); }
    .side-bar ul {
      list-style: none;
      padding: 0; }
      .side-bar ul li a {
        display: block;
        padding: 3px 12px; }
      .side-bar ul li.active a {
        color: #ff8d00; } */
.performance-ul {
display: flex;
justify-content: center;
list-style-type: none;
min-width: fit-content;
}
.performance-ul li {
display: inline;
padding: 1% 5% 2% 5%
}
.performance-ul li a {
    font-size: 1.2em;
    font-weight: 600;
    color: #602A48;
}
/* .page-content {
padding-left: 260px; 
}
.performance-subnav {
border: 1px solid blue;
} */
.sidebar-test-type {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 1em;
}
.bp3-control.bp3-checkbox.bp3-inline.filter-A, 
.bp3-control.bp3-checkbox.bp3-inline.filter-M,
.bp3-control.bp3-checkbox.bp3-inline.filter-P, 
.bp3-control.bp3-checkbox.bp3-inline.filter-SR, 
.bp3-control.bp3-checkbox.bp3-inline.filter-M2 {
    margin: 0 30px 12px 0;
}
.sidebar-header {
    text-align: center;
    margin: 7% 0 5% 0;
    font-weight: 600;
}
.sidebar-datePicker {
    min-height: 80px;
    margin: 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.bp3-popover-wrapper.datePickerInput {
    margin-bottom: 5%;
}

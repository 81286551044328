
.providerMenu {
    height: 60vh;
    overflow-y: auto;
    min-width: 100%;
}
.subheader {
    display: flex;
    margin: 1em 0 1.5em 0;
    justify-content: space-between;
    align-items: center;
}
#calendarBtn {
    padding: 1em;
}

.clinicControls {
    margin-bottom: 1%;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: flex-start;
}

.kpiCol{
   display: flex;
   flex-direction: column;
   align-items: space-evenly;
}

/* .bp3-input-group {
    max-width: 50%;
} */
.arrayqc {
    margin: 0;
    text-align: center;
}
.statContainer {
    display: flex;
    margin-top: 5%;
    justify-content: center;
    align-items: baseline;
    min-width: fit-content;
}
.refContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
    width: 100%;
}

.statHeader-dqc, .statHeader-callrate, .statHeader-mapd {
    display: inline;
    margin-top: 0;
    margin-bottom: 5%;
    font-size: 2.8em;
    margin-right: .2em;
}

.statHeader, .statHeader-failureRate, .statHeader-aneuploidRate {
    display: inline;
    margin-top: 0;
    margin-bottom: 5%;
    font-size: 3.5em;
    margin-right: .3em;
}
.deltaHistory {
    display: inline-block;
}
.refHeader {
    display: block;
    margin-top: 0;
    margin-bottom: .8em;
}
.refRange {
    display: block;
    margin-top: 0;
    margin-bottom: .3em;
    color: red
}
.rebiopsy {
    text-align: center;
}
.bp3-button-group::selection {
    background-color: #2F80ED;
}

.bp3-button.samplesBtn-:hover, .bp3-button.cyclesBtn-:hover{
    color:white;
    background: linear-gradient(to bottom, rgba(125, 188, 255, 1), rgba(125, 188, 255, 0.6));
}
.bp3-button.samplesBtn-clicked, .bp3-button.cyclesBtn-clicked {
    color: white;
    background-color: #2F80ED;
    background-image: none;
}

.bp3-button.samplesBtn-clicked:hover, .bp3-button.cyclesBtn-clicked:hover{
    color: white;
    background: linear-gradient(to bottom, rgba(125, 188, 255, 1), rgba(125, 188, 255, 0.6));

}
.positiveInf {
    color: green
}
.negativeInf {
    color: red
}


